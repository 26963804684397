import "./style.css";
import scrollLogo from "./../../img/logo.png"
import {NavLink} from "react-router-dom";


import {useAccount} from 'wagmi'
import { ethers } from 'ethers'
import { setIntervalAsync } from "set-interval-async/dynamic";
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider, lightTheme,
  Chain  
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { mainnet, sepolia } from 'wagmi'

const Scroll: Chain = {
  id: 534352,
  name: 'Scroll Mainnet',
  network: 'Scroll',
  iconUrl: 'https://scroll.io/logo.png',
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Scroll',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.scroll.io'] },
    default: { http: ['https://rpc.scroll.io'] },
  },
  blockExplorers: {
    default: { name: 'ScrollScan', url: 'https://scrollscan.com' },
    etherscan: { name: 'ScrollScan', url: 'https://scrollscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11_907_934,
    },
  },
  testnet: false,
};

const { provider, chains } = configureChains(
  [mainnet,Scroll],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'RainbowKit Mint NFT Demo',
  projectId: '9c64e367da4b5b6e2af8b386d0b21b19',
  chains,
});
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})



const Navbar = () => {
    return ( 

    <nav className="nav">
        <div className="container">
            <nav className="nav-row">
                <div className="logo">
                <NavLink to="/" className="logo">
                <img className="logo-picture" src={scrollLogo} alt="" /><strong>Scrolls</strong>Chat
                </NavLink></div>
                
                
               <ul className="nav-list">     
                  <li className="nav-list__item"><NavLink to="/" className="nav-list__link nav-list__link--active">Home</NavLink></li>
                  <li className="nav-list__item"><NavLink to="./chat" className="nav-list__link">Chat</NavLink></li>
                  <li className="nav-list__item"><NavLink to="./homocryptus" className="nav-list__link">Homo Cryptus</NavLink></li>
                  <li className="nav-list__item"><NavLink to="./NFTList" className="nav-list__link">Mint NFT</NavLink></li>
                  
                  
                  </ul> 
                  <ConnectButton className="wallet-connect-button"/>                 
            </nav>
        </div>   
    </nav>
   
    
    
     );
}
 
export default Navbar;