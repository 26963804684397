import "./../styles/main.css"
import { NavLink } from "react-router-dom";
import TwitterLogo from "./../img/logo-X-black.png"

const Home = () => {
    return ( <>
        
<header className="header">
      
    <div className="header-wrapper">
          <h1 className="header-title">
            <strong>Scrolls Chat</strong><br/>
            is a social web3 communication format <br/>that is stored in contracts inside the new <p className="header-title-accent">Scroll blockchain</p>   
          </h1>
          <div className="header-text">
                <p>Become an early Scroll user and leave your step on the new blockchain. <br/>Socialize in the Chat room. Be active, make transactions <br/>and get rewards in the future.</p>
          </div>          
          <NavLink to="./chat" className="btn">Chat Room</NavLink>
          <div className="twitter-link">
                <a  class="nav-list__link nav-list__link--active active" href="https://twitter.com/ScrollsChat" target="_blank" aria-current="page">
                      <img className="logo-picture" src={TwitterLogo} alt="" />                
                </a> 
            </div> 
    </div>
    
</header>

<main className="main"></main>
</>
);

    
}
 
export default Home;