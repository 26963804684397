import "./styles/main.css";
import { useState } from 'react';
//// sfc команда
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import Chat from "./pages/Chat";
import Early from "./pages/Earlyadopter";
import NFTList from "./pages/NFTList";
import HomoMint from "./pages/homoMint";

import {useAccount} from 'wagmi'
import { ethers } from 'ethers'
import Storemessage  from "./components/postmessage/Storemessage";
import * as config from './pages/config'
import {  getDefaultWallets,  RainbowKitProvider, lightTheme,  Chain  } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { ConnectButton , darkTheme} from '@rainbow-me/rainbowkit';
import Aclasses from './MintButton.module.css'
import { setIntervalAsync } from "set-interval-async/dynamic";
import { mainnet, sepolia } from 'wagmi'


const Scroll: Chain = {
  id: 534352,
  name: 'Scroll Mainnet',
  network: 'Scroll',
  iconUrl: 'https://scroll.io/logo.png',
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Scroll',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.scroll.io'] },
    default: { http: ['https://rpc.scroll.io'] },
  },
  blockExplorers: {
    default: { name: 'ScrollScan', url: 'https://scrollscan.com' },
    etherscan: { name: 'ScrollScan', url: 'https://scrollscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11_907_934,
    },
  },
  testnet: false,
};

const { provider, chains } = configureChains(
  [mainnet,Scroll],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'RainbowKit Mint NFT Demo',
  projectId: '9c64e367da4b5b6e2af8b386d0b21b19',
  chains,
});


const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

function App() {  
    return (
        <WagmiConfig client={wagmiClient}> 
        <RainbowKitProvider chains={chains} modalSize="compact" coolMode theme={lightTheme({ accentColor: '#ff684b',  })}>
          <Router> 
            <Navbar />
             <Routes>
                <Route path="/" element={<Home/>}  />
                <Route path="/chat" element={<Chat/>}  />
                <Route path="/earlynft" element={<Early/>}  /> 
                <Route path="/nftlist" element={<NFTList/>}  /> 
                <Route path="/homocryptus" element={<HomoMint/>}/>
                </Routes>  
          </Router>     
          {/*  <Footer/>  */}   
          </RainbowKitProvider>    
        </WagmiConfig>
    );  


}


export default App;



