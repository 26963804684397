import * as React from 'react'
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractRead,
  } from 'wagmi'
import Web3, { ResponseError , getTransactionCount, eth} from 'web3'
import classes from './poststyles.css'

//If the provider supports it web3.eth.getTransactionCount(addr, "pending") should return the total transaction by addr including pending transactions and ``web3.eth.getTransactionCount(addr)` is the total confirmed transactions. – 


function MintNFTHomo ({ ABI, contractAddress}) {
  
      const { config, refetch } = usePrepareContractWrite({
        address: contractAddress,
        abi: ABI,        
        functionName: 'mint',        
        chainId: 534352,        
        overrides: {            value: Web3.utils.toWei('0.0006', 'ether')         },
        
    })

    const {  data, write } =  useContractWrite(config)
    const checkMinted = usePrepareContractWrite(config)
    const { isLoading, isSuccess } = useWaitForTransaction({
      hash: data?.hash,
    })
    
  
   

        return (
      <div className='mintHomo-container'>
        <div style={{'padding-bottom': '15px', 'margin-top':'-30px',   'font-size': '12px',    'width': '292px',    'text-align': 'center',    'color': 'gray'}}>Price 0.0006 eth</div>
      
        <button className='mintHomoButton'   disabled={ !write || isLoading   } onClick={() => write()}>
            
           {!write ? 'Mint': isLoading  ? 'Minting...' : 'Mint'}
      </button>
      <br></br>
       {isSuccess && (   
        <div id="GreenMsg" className='SendMsg'>
          <div style={{'font-size':'12px'}} >Successfully minted<br></br>NFT</div>
          
          <div style={{'font-size':'14px', 'margin-top':'4px'}}>
            <a href={`https://twitter.com/intent/tweet?text=I+have+successfully+minted+Homo+Cryptus+on+SCROLL+blockchain+%21+At+https%3A%2F%2FScrolls.chat+++Don%27t+miss+out+%21+`} target="_blank">SHARE ON TWITTER</a> 
          </div>
        </div>
       )} 
    </div>
   )
   
 };




export default MintNFTHomo;
