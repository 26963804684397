import * as React from 'react'
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractRead,
  } from 'wagmi'
import Web3, { ResponseError , getTransactionCount, eth} from 'web3'
import classes from './poststyles.css'

//If the provider supports it web3.eth.getTransactionCount(addr, "pending") should return the total transaction by addr including pending transactions and ``web3.eth.getTransactionCount(addr)` is the total confirmed transactions. – 


function MintNftAnyUnlim ({ ABI, contractAddress, value}) {
  
      const { config, refetch } = usePrepareContractWrite({
        address: contractAddress,
        abi: ABI,        
        functionName: 'mint',        
        chainId: 534352,        
        overrides: {            value: Web3.utils.toWei(value, 'ether')         },       

    })

    const {  data, write } =  useContractWrite(config)
    const checkMinted = usePrepareContractWrite(config)
    const { isLoading, isSuccess } = useWaitForTransaction({
      hash: data?.hash,
    })
       
        return (
      <div id='mintClick' style={{'display': 'flex',      'justify-content': 'center'  }}>
        <button className='mintButton'   disabled={ !write || isLoading || !checkMinted.isSuccess   } onClick={() => write()}>
            
           {!write ? 'Mint': isLoading  ? 'Minting...' : 'Mint'}
      </button>
      <br></br>   
      
       {isSuccess && (  
        <div id="GreenMsg" className='SendMsg'>
          <div style={{'font-size':'12px'}} >Successfully minted<br></br>NFT</div>
          
          <div style={{'font-size':'14px', 'margin-top':'4px'}}>
            <a href={`https://twitter.com/intent/tweet?text=I+have+successfully+minted+EarlyNFT+on+SCROLL+blockchain+%21+At+https%3A%2F%2FScrolls.chat+++Don%27t+miss+out+%21+`} target="_blank">SHARE ON TWITTER</a> 
          </div>
        </div>
        
       )} 

    </div>
   )
   
 };




export default MintNftAnyUnlim;
