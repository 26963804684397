import "./../styles/main.css";
import { useState,useCallback,useEffect } from 'react';
//// sfc команда
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {useAccount,useEnsName,useContractRead} from 'wagmi'
import { ethers, JsonRpcProvider } from 'ethers'
import Storemessage  from "./../components/postmessage/Storemessage";
import * as config from './../pages/config'
import {  getDefaultWallets,  RainbowKitProvider, lightTheme,  Chain  } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import Aclasses from './../MintButton.module.css'
import { setIntervalAsync } from "set-interval-async/dynamic";
import { readContract } from '@wagmi/core'

import { render } from 'react-dom';
import { mainnet, sepolia } from 'wagmi'
import * as EarlyConfig from './EarlyAbiConfig'
import Web3, { ResponseError } from 'web3'

const Scroll: Chain = {
  id: 534352,
  name: 'Scroll Mainnet',
  network: 'Scroll',
  iconUrl: 'https://scroll.io/logo.png',
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Scroll',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.scroll.io'] },
    default: { http: ['https://rpc.scroll.io'] },
  },
  blockExplorers: {
    default: { name: 'ScrollScan', url: 'https://scrollscan.com' },
    etherscan: { name: 'ScrollScan', url: 'https://scrollscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11_907_934,
    },
  },
  testnet: false,
};

const { provider, chains } = configureChains(
  [Scroll,],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'RainbowKit Mint NFT Demo',
  projectId: '9c64e367da4b5b6e2af8b386d0b21b19',
  chains,
});


const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

let ScrollCheck =0;

function Chat() {
  const [message, setMessage] = useState('');
  const handleChangeM = (event) => { setMessage(event.target.value);  };
  const { address, connector, isConnected } = useAccount()
  let ensName = useEnsName({
    address: address,
    cacheTime: 0,
    chainId: 1,
    
  })
  
  const {data:minted} = useContractRead({
    address: EarlyConfig.contractAddress,
          abi: EarlyConfig.ABI,
          functionName: 'balanceOf',        
          chainId: 534352,
          args: [address],
  });
 
   let mintedCheck=0;
 if (minted != null) {  //console.log(Web3.utils.hexToNumber(minted._hex)); 
                        mintedCheck = Web3.utils.hexToNumber(minted._hex);
                     }
  let resultAddress = address;
  if (ensName.data!==undefined && ensName.data!==null) {resultAddress=ensName.data}
  if (mintedCheck>0) { resultAddress='🐤'+resultAddress}
  
  //console.log(ensName.data)
     return (
       
                
        <div className="App" > 
                    
          <div className="chat-content">
            
            <div className="chat-wrapper">
                  <div className="chat-header">International Scroll Chat room</div>
                  <div className="chat-welcome-message">Welcome {resultAddress}!</div>
                  <textarea  id="ScrollChat" className='chat-window' readOnly></textarea> 
                  <div className="chat-inputs">
                      <input id="MInput"  className="chat-input-message" placeholder="Type your message.." type="text" name="message" onChange={handleChangeM} maxLength="250"     />
                      <Storemessage  PostMessage = { message }
                        PostName = {resultAddress}
                        ABI = {config.ABI} 
                        contractAddress = {config.contractAddress} />                   
                  </div>
                  <div className="chat-hello-message">
                    <p>🐤 - <a href='https://element.market/collections/scroll-earlyBird' target="_blank" rel="Early NFT">Early Bird NFT</a>  holder</p>
                    <p>All messages is saved in a blockchain contract and stored forever.</p>
                    <p>But you can see only last messages.</p>
                  </div>

                  
            </div>
          </div>                      
         </div>
                
            
    );
    
}


  async function ChatRead() {  
    
        //const walletAddress = accounts[0]    // first account in MetaMask
        //const signer = provider.getSigner(walletAddress)
        
 
        const ChatArray = await readContract({
          address: config.contractAddress,
          abi: config.ABI,
          functionName: 'getMessages',
          chainId: 534352,
        })
    
    
        /* const Chatabi = config.ABI
        const ChatAddress = config.contractAddress
        const ChatContract = new ethers.Contract(ChatAddress, Chatabi, provider)
        const ChatArray = await ChatContract.getMessages() */
        
  /* const PROVIDER_URL = "https://scroll-mainnet.chainstacklabs.com";
  const provider = new ethers.providers.Web3Provider(PROVIDER_URL);
  const ChatContract = new ethers.Contract(config.contractAddress, config.ABI, provider);
  const ChatArray = await ChatContract.getMessages()
 */

    
        let chatStr ='';
        let nName ='';
        let nMessage='';
        let nStr='';

           for (let i = 1; i <= 99; i++) {
            nName=ChatArray[0][i];
            nMessage=ChatArray[1][i];
             if ((nMessage!=='')&&(nName!=='')) {
            
            if (nName.length>=42){nName = nName.substr(0, nName.length-36)+'...'+nName.substr(38, 42)            
              ;}
            
             nMessage = nMessage.replace(/putin/g, 'Zeliboba');  
             nMessage = nMessage.replace(/Putin/g, 'Zeliboba'); 
             nMessage = nMessage.replace(/Ukraine/g, 'Urkaine'); 
            nStr=nName+': '+nMessage+' \n';
            chatStr+=nStr;}
           }

           if (chatStr===''){ chatStr='Looks like you even dont have any wallet. Try to connect your wallet to see messages'}
         
          if(window.document.getElementById('ScrollChat')!= null){ 
            if (window.document.getElementById('ScrollChat').value==='') {ScrollCheck=0}
            window.document.getElementById('ScrollChat').value =chatStr;
            window.document.getElementById('ScrollChat').readOnly = true;
            if (ScrollCheck===0){ScrollCheck=1;
                window.document.getElementById('ScrollChat').scrollTop=
                window.document.getElementById('ScrollChat').scrollHeight; 
            }
          }
        console.log('ChatRead ended')   

}

ChatRead();    

      setIntervalAsync(function() { ChatRead();  }, 20000);


     setIntervalAsync(function() { 
         if(window.document.getElementById('ScrollChat')!= null){ 
             if (window.document.getElementById('ScrollChat').value===''){ChatRead(); }
         }; 
         
     }, 4000);





export default Chat;



