import MintnftAny  from "./../components/mintnft/MintNftAny";
import MintnftAnyUnlim  from "./../components/mintnft/MintNftAnyUnlim";
import * as EarlyConfig from './EarlyAbiConfig'
import nftPic from "./../img/_EarlyBird.gif"
import passportPic from "./../img/passport.png"
import DegenBage from "./../img/DegenScroll.png"
import SybilTatoo from "./../img/ScrollSybil.png"
import LFGRoket from "./../img/scrollRocket.png"
import "./../styles/main.css";




const Early = () => {
    return ( <>
         
    <div className="header-early-list">      
            
            <div className="early-wrapper">
                  <h1 className="early-title">
                    <strong>Degen patch</strong><br></br>                     
                  </h1>
                  <div className="nft-text">
                        <p className="nft-text-line">Artfully embroidered Scroll Degen velcro patch. It can decorate your jacket or bag maybe?</p>
                        <p className="nft-text-line">Unlimit freemint</p> 
                  </div>                  
                  <div className="nftbox">
                        <img className="nft-picture" src={DegenBage} alt="" />
                        <MintnftAnyUnlim ABI={EarlyConfig.ABI} contractAddress='0x8a317299fc7ad2c8fcde76f55b1b9f62ed4b39aa' value = '0.00004'/> 
                  </div>                  
            </div>

            <div className="early-wrapper">
                  <h1 className="early-title">
                    <strong>Scrolls Passport</strong><br></br>                     
                  </h1>
                  <div className="nft-text">
                        <p className="nft-text-line">Do you want to become a full citizen of the country of Ethereum with all benifits?</p>
                        <p className="nft-text-line">One per wallet, 0.00015 eth</p>                        
                  </div>                  
                  <div className="nftbox">
                        <img className="nft-picture" src={passportPic} alt="" />                        
                        <MintnftAny ABI={EarlyConfig.ABI} contractAddress='0x53e954e7a821c6dEB8d534a7d459F2321f35bA21' value = '0.00015'/>
                  </div>
                  
                  
            </div>

            
            <div className="early-wrapper">
                  <h1 className="early-title">
                    <strong>Rocket sign</strong><br></br>                     
                  </h1>
                  <div className="nft-text">
                        <p className="nft-text-line">Small antique glazed metal badge with a space rocket and the LFG inscription</p>
                        <p className="nft-text-line">One per wallet, 0.0003 eth</p>  
                  </div>                  
                  <div className="nftbox">
                        <img className="nft-picture" style={{'scale': '0.5' }} src={LFGRoket} alt="" />
                        <MintnftAny ABI={EarlyConfig.ABI} contractAddress='0x093C1197dA672b5615656785493311DAAFf20092' value = '0.0003'/> 
                  </div>                  
            </div>  

            <div className="early-wrapper">
                  <h1 className="early-title">
                    <strong>True Sybil tatoo</strong><br></br>                     
                  </h1>
                  <div className="nft-text">
                        <p className="nft-text-line">You were googling down the street and wandered into a tattoo parlor. It seems like you're ready to get this one.... </p>
                        <p className="nft-text-line">One per body, invaluable</p>  
                  </div>                  
                  <div className="nftbox">
                        <img className="nft-picture" style={{'scale': '0.8' }} src={SybilTatoo} alt="" />
                        <MintnftAny ABI={EarlyConfig.ABI} contractAddress='0x1b455FA88a8Bd8FDAeDe173EdEf1e1072d8B6fda' value = '0.0003'/> 
                  </div>                  
            </div>         
            
            
    </div>
    
    </>
     );
}
 
export default Early;