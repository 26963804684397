import Mintnft  from "./../components/mintnft/MintNft";
import * as EarlyConfig from './EarlyAbiConfig'
import nftPic from "./../img/_EarlyBird.gif"
import "./../styles/main.css";

const Early = () => {
    return ( <>
         
    <div className="header">
      
            <div className="early-wrapper">
                  <h1 className="early-title">
                    <strong>Early user NFT</strong><br></br>                     
                  </h1>
                  <div className="nft-text">
                        <p>Send at least one message in the chat room to be eligible to mint Early Bird NFT</p>
                  </div>                  
                  <div className="nftbox">
                        <img className="nft-picture" src={nftPic} alt="" /> 
                        
                        
                        
                                     <Mintnft ABI={EarlyConfig.ABI} contractAddress={EarlyConfig.contractAddress}/>
                                   
                        
                  </div>
                  
            </div>
            
    </div>
    
    </>
     );
}
 
export default Early;