import * as React from 'react'
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractRead,
  } from 'wagmi'
import Web3, { ResponseError } from 'web3'
import classes from './poststyles.css'



function MintNft ({ ABI, contractAddress}) {
  
      const { config, refetch } = usePrepareContractWrite({
        address: contractAddress,
        abi: ABI,        
        functionName: 'mint',        
        chainId: 534352,
        scopeKey: 'nftEarly',
        overrides: {            value: Web3.utils.toWei('0.0004', 'ether')         },
        watch: true,
        cacheOnBlock: true,

    })

    const {  data, write } =  useContractWrite(config)
    const checkMinted = usePrepareContractWrite(config)
    const { isLoading, isSuccess } = useWaitForTransaction({
      hash: data?.hash,
    })
      
    const {data:minted} = useContractRead({
      address: contractAddress,
            abi: ABI,
            functionName: 'totalSupply',        
            chainId: 534352,            
    });
    let totalMinted=0;
    let supplyCheck = true;
   if (minted != null) {
    console.log(Web3.utils.hexToNumber(minted._hex)); 
    totalMinted = Web3.utils.hexToNumber(minted._hex);
    if (totalMinted<1000) {supplyCheck=false}
   }

        return (
      <div>
        <button className='mintButton'   disabled={ !write || isLoading || isSuccess ||supplyCheck|| !checkMinted.isSuccess   } onClick={() => write()}>
            
           {!write ? 'Mint': isLoading  ? 'Minting...' : 'Mint'}
      </button>
      <br></br>
      <div style={{'padding-top': '10px',    'font-size': '12px',    'width': '292px',    'text-align': 'center',    'color': 'gray'}}> {totalMinted} of 1000 minted </div>
       {isSuccess && (   
        <div id="GreenMsg" className='SendMsg'>
          <div style={{'font-size':'12px'}} >Successfully minted<br></br>NFT</div>
          
          <div style={{'font-size':'14px', 'margin-top':'4px'}}>
            <a href={`https://twitter.com/intent/tweet?text=I+have+successfully+minted+EarlyNFT+on+SCROLL+blockchain+%21+At+https%3A%2F%2FScrolls.chat+++Don%27t+miss+out+%21+`} target="_blank">SHARE ON TWITTER</a> 
          </div>
        </div>
       )} 
    </div>
   )
   
 };




export default MintNft;
