import MintNFTHomo  from "./../components/mintnft/MintHomo";
import MintnftAnyUnlim  from "./../components/mintnft/MintNftAnyUnlim";
import * as EarlyConfig from './EarlyAbiConfig'
import "./../styles/main.css";
import homoBanner from "./../img/_monkey_banner.png"



const homoMint = () => {
    return ( <>
         
    <div className="homo-header">
      
            <div className="homo-wrapper">
                  <h1 className="early-title">
                    <strong>Homo Cryptus</strong><br></br>                     
                  </h1>
                  <div className="homo-nft-text">
                        <p>The first human to pick up a rock and create cryptocurrency</p>
                  </div>                  
                  <div className="nftbox">
                  <img className="nft-picture" src={homoBanner} alt="" />
                  <MintNFTHomo ABI={EarlyConfig.ABI} contractAddress='0x988a1df9760956dd0c4eab26a15e1a2a38063df3' /> 
                   </div>                  
            </div>

            <div className="homo-faq">
                  <h1 className="early-title">
                    <strong>FAQ</strong><br></br>                     
                  </h1>
                  <div className="homo-faq-nft-text">
                        <p className="faq-text-quest">What is 'Homo Crypto'?</p> 
                        <p className="nft-text-line">Its nice painted PFP collection on the Scroll blockchain.</p>                                               
                  </div>  
                  
                  <div className="homo-faq-nft-text">
                        <p className="faq-text-quest">What is the Total Supply?</p> 
                        <p className="nft-text-line">Total 999 unique NFT in this collection.</p>                                               
                  </div>
                  <div className="homo-faq-nft-text">
                        <p className="faq-text-quest">How I can get WL!</p> 
                        <p className="nft-text-line">Fully public mint, no WLs.</p>                                               
                  </div>
                  <div className="homo-faq-nft-text">
                        <p className="faq-text-quest">What is the price?</p> 
                        <p className="nft-text-line">0.0006 eth</p>                                               
                  </div>
                  <div className="homo-faq-nft-text">
                        <p className="faq-text-quest">When is the Public Mint?</p> 
                        <p className="nft-text-line">5pm UTC, Saturday 16/03/24</p>                                               
                  </div>
                  <div className="homo-faq-nft-text">
                        <p className="faq-text-quest">Can anyone participate in the Public Mint?</p> 
                        <p className="nft-text-line">Yes.</p>                                               
                  </div>
                  <div className="homo-faq-nft-text">
                        <p className="faq-text-quest">Can I mint from a smart contract?</p> 
                        <p className="nft-text-line">Yes.</p>                                               
                  </div>
                  <div className="homo-faq-nft-text">
                        <p className="faq-text-quest">Will there be a Homo Cryptus Discord?</p> 
                        <p className="nft-text-line">If we are talking about official, then there won’t be.</p>                                               
                  </div>

            </div>
            
    </div>
    
    </>
     );
}
 
export default homoMint;