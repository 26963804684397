import * as React from 'react'
import {
  usePrepareContractWrite,
  useContractWrite,
  useAccount,
  useWaitForTransaction,
} from 'wagmi'
import Web3 from 'web3'
import classes from './poststyles.css'

function Storemessage ({PostMessage, PostName, ABI, contractAddress}) {
    const { config } = usePrepareContractWrite({
        address: contractAddress,
        abi: ABI,
        //gas: 65118,
        functionName: 'postNewMessage',
        args: [PostName,PostMessage],
        chainId: 534352,
        //args: [PostMessage],
        overrides: {
            value: Web3.utils.toWei('0.000015', 'ether')
        }
    })

    const { data, write } = useContractWrite(config)
    
 
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })
 
  return (
    <div>
      <button className='mintButton'   disabled={!write || isLoading} onClick={() => write()}>
          
         {!write ? 'Wrong Network': isLoading  ? 'Posting...' : 'Post to Scroll'}
      </button>
       {isSuccess && (   
        <div id="GreenMsg" className='SendMsg'>
          <div style={{'font-size':'12px'}} >Message successfully <br></br>posted to Scroll blockchain</div>
          
          <div style={{'font-size':'14px', 'margin-top':'4px'}}>
            <a href={`https://twitter.com/intent/tweet?text=I%20have%20successfully%20posted%20message%20at%20SCROLL%20blockchain%20Chat!%20on%20https://Scrolls.chat%20And%20now%20I%20eligible%20to%20claim%20Early%20NFT.%20%20Don't%20miss%20out%20!`} target="_blank">SHARE ON TWITTER</a> 
          </div>
        </div>
       )} 
    </div>
   )
   if (isSuccess){document.getElementById('MInput').value =''
   let ss=document.getElementById("GreenMsg").style.animationName
   document.getElementById("GreenMsg").style.animationName='a2'
   document.getElementById("GreenMsg").style.animationName=ss;};
 };




export default Storemessage;
